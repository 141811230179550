import React from "react"

import SEO from "../components/seo"

import img from '../images/undraw_not_found_60pq.svg'

const NotFoundPage = () => (
  <div className="w-screen h-screen bg-grey">
    <SEO title="404: Not found" />
    <div className="flex flex-col justify-center h-screen ">
      <img className="mx-auto w-1/2 h-auto my-8" src={img} alt="page not found" />
      <div className="text-gray-700 text-center text-xl text-bold font-bold">Page Not Found</div>
    </div>
  </div>
)

export default NotFoundPage
